<template>
  <div class="main-play-wrapper">
    <div
      class="palyer-wrapper"
      @click="isMobile && playContent(selectedItem, $event)"
    >
      <figure
        style="width: 100%; height: 100%;background-color: #000000;position: absolute; top: 0; left: 0;"
        v-if="selectedItem"
      >
        <ResponsivePlayer
          :playerPayload="selectedItem"
          :isPlayTrailer="isPlayTrailer"
          v-if="acceptPlayer && !isMobileDevice"
          @playBackEndedListner="playBackEndedListner"
        />
        <img
          :src="getPoster(selectedItem, 'mainslide')"
          :alt="titleShow(selectedItem)"
          style="width: 100%; height: 100%;object-fit: cover;transition: 2s;"
          v-else
        />
      </figure>
    </div>
    <div class="feat-container">
      <div class="feature-carousel-container">
        <div class="feature-slider-header">
          <div class="feature-slider-head">
            <div class="feature-slider-head-btns">
              <div
                :id="`feature-slider-controls`"
                class="feature-slider-controls"
                v-if="contents.length > 4"
              >
                <button
                  type="button"
                  class="feature-prev"
                  @click="sliderPrevClicked()"
                >
                  <img
                    src="@/assets/icons/less-than-filled-50.png"
                    width="15px"
                    alt="prev"
                  />
                </button>
                <!-- next-arrow -->
                <button
                  type="button"
                  class="feature-forward"
                  @click="sliderNextClicked()"
                >
                  <img
                    src="@/assets/icons/more-than-filled-50.png"
                    width="15px"
                    alt="for"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="featured-carousel" id="featured-carousel">
          <div class="feat-card" v-for="(item, key) in contents" :key="key">
            <div tabindex="1" class="feat-card-container">
              <div
                class="feat-card-box-contain"
                :id="`card-${key}`"
                :objectid="item.objectid"
                :title="titleShow(item)"
                @click="playContentWrapper(item, $event, key)"
                :style="[{ 'padding-top': `56%` }]"
                tabindex="0"
              >
                <figure
                  style="width: 100%; height: 100%;background-color: #000000;position: absolute; top: 0; left: 0;"
                >
                  <img
                    :src="getPoster(item, 'thumbnail')"
                    :alt="titleShow(item)"
                    style="width: 100%; height: 100%;object-fit: cover;"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
        <div class="feat-nav-container" id="feat-nav-container">
          <button
            class="nav-button"
            type="button"
            v-for="(item, key) in contents"
            :key="key"
            @click="navBtnClicked(key)"
          ></button>
        </div>
      </div>
      <AgeRestrictionModal
        @onActionComplete="onAgeModalActionAccept"
        v-if="showAgePopUp"
      />
      <PinModal
        id="pc-pin-detail-modal-player-config"
        :open="showPinModal"
        @onClose="closePinModal"
        :mode="pinMode"
        @onActionComplete="onPinModalActionComplete"
        :pinError="pinModalError"
        :callbackParentalLockFlag="callbackParentalLockFlag"
        :loading="pinModalLoading"
        @onForgotPin="openForgotPinModal"
      />
      <ForgotPinModal
        id="pc-forgot-pin-modal"
        :open="showForgotPinModal"
        @onClose="closeForgotPinModal"
        @onActionComplete="onForgotPinModalActionComplete"
        :pinError="forgotPinModalError"
        @onResend="resendOtp"
        :loading="loading"
      />
    </div>
    <div class="details-card" v-if="selectedItem">
      <div class="title-head">
        <h1>{{ selectedItem.title }}</h1>
      </div>
      <div class="year-duration-tags">
        <ul>
          <li class="year" v-if="selectedItem.productionYear">
            {{ selectedItem.productionYear }}
          </li>
          <li class="duration" v-if="selectedItem.duration">
            {{ durationFormat(selectedItem.duration) }}
          </li>
          <li class="pg-rating" v-if="selectedItem.pgrating">
            {{ selectedItem.pgrating }}
          </li>
        </ul>
      </div>
      <div class="description">
        {{ selectedItem.longdescription }}
      </div>
      <div class="category-genre-tag">
        <ul>
          <li class="category" v-if="selectedItem.category">{{this.$t(selectedItem.category)}}</li>
          <li class="bullet" v-if="selectedItem.genre">&bull;</li>
          <li class="genre" v-if="selectedItem.genre">{{ this.$t(selectedItem.genre) }}</li>
          <li class="sun-genre" v-if="selectedItem.subgenre">
            <div
              v-for="(item, i) in selectedItem.subgenre"
              class="sun-genre-list"
            >
              <li class="bullet">&bull;</li>
              <li>{{ $t(item) }}</li>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="action-buttons">
      <div class="play-button" @click="playContent(selectedItem, $event)">
        <img src="@/assets/icons/playSmall.svg" /> {{ $t("Play") }}
      </div>
      <div class="more-info-button" @click="playContent(selectedItem, $event)">
        {{ $t("More Info") }}
      </div>
      <!-- <div class="add-watch-list"><img src="@/assets/icons/addToMyList.svg" /> Add to My List</div> -->
      <!-- <div class="add-watch-list"> <WatchListBtn :content="selectedItem" /> Add to My List</div> -->
    </div>
  </div>
</template>

<script>
import { tns } from "tiny-slider/src/tiny-slider.module";
import { mapActions, mapGetters } from "vuex";
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";
import { _projectName } from "@/provider-config.js";
export default {
  name: "FeaturedCarousel",
  props: {
    featuredScreen: {
      type: Object,
    },
    contents: {
      type: Array,
    },
  },
  data() {
    return {
      // enableFeatured: false,
      isMobile: false,
      isMobileDevice: false,
      cardHeight: 430,
      slider: null,
      triangleColor: null,
      toolTipColor: null,
      localDisplayLang: null,
      counter: 1,
      activeIndex: 0,
      isPlayTrailer: true,
      showAgePopUp: false,
      allowAutoPlay: false,
      showPinModal: false,
      showForgotPinModal: false,
      pinModalLoading: false,
      parentalControlsEnabled: false,
      callbackParentalLockFlag: "",
      pinModalError: "error message",
      pinMode: "restrict",
      forgotPinModalError: "",
      forgetpinloader: false,
      loading: false,
      autoPlayInFeatureCaurosel: false,
      selectedItem: null,
      acceptPlayer: false,
    };
  },
  computed: {
    ...mapGetters([
      "getToken",
      "subscriberid",
      "availabilityList",
      "subscriptionList",
      "purchaseList",
      "getRtl",
      "getDisplayLanguageCode",
      "appConfig",
    ]),
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    this.localDisplayLang =
      this.localDisplayLang == "hi" ? "hin" : this.localDisplayLang;
    let isEnabled =
      localStorage.getItem("parentalControlEnabled") == "true" ? true : false;
    this.parentalControlsEnabled = isEnabled;
    this.actChangeBackgroundCarousel();

    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
      this.actChangeBackgroundCarousel();
    });
  },
  mounted() {
    // this.contents = [...this.contents, ...this.contents];
    this.isMobile = window.innerWidth <= 768;
    window.addEventListener("resize", this.updateIsMobile);
    this.checkIfMobileDevice();
    window.addEventListener("resize", this.checkIfMobileDevice);
    this.selectedItem = this.contents[0];
    // this.autoPlayInFeatureCaurosel = this.appConfig?.featureEnabled?.autoPlayInFeatureCaurosel;
    this.autoPlayInFeatureCaurosel = true;
    this.renderCarousel();
  },
  methods: {
    ...mapActions([
      "getScreenContents",
      "parentalControlVerifyOldPin",
      "parentalControlPinSet",
      "parentalControlResetPin",
    ]),
    updateIsMobile() {
      this.isMobile = window.innerWidth <= 600;
    },
    checkIfMobileDevice() {
      this.isMobileDevice = window.innerWidth <= 600;
    },
    getUpdatedTag(tags) {
      if (!tags) return "";
      let configCTTag = "";
      for (let tag of tags) {
        if (tag.includes("CT-")) {
          configCTTag = tag.split("CT-")[1];
          if (this.appConfig.contentTypeTags) {
            let language = "en";
            for (let lang of window.PROVIDER_SETUP_CONFIG.supportLanguageList) {
              if (
                lang.includes(localStorage.getItem("setDisplayLanguageCode"))
              ) {
                language = lang;
              }
            }
            configCTTag =
              this.appConfig.contentTypeTags[language][configCTTag] ||
              configCTTag;
            break;
          }
        }
      }
      return configCTTag;
    },
    shouldShowDuration(item) {
      return item.duration == 30;
    },
    actChangeBackgroundCarousel() {
      let deg = this.localDisplayLang == "eng" ? "90deg" : "-90deg";
      document.documentElement.style.setProperty(
        "--backgroud-carousel-color-rotation",
        deg
      );
    },
    isObjectTag(content) {
      if (content.hasOwnProperty("objecttag")) {
        if (Array.isArray(content.objecttag)) {
          return content.objecttag.length === 0 ? false : true;
        } else {
          return content.objecttag === null ? false : true;
        }
      }
    },
    pickTagForContent(content) {
      if (content.hasOwnProperty("objecttag")) {
        let tag = content.objecttag[0];
        let contenttags = this.appConfig.contentTags;
        if (this.localDisplayLang === "hin") {
          return contenttags.ar[`${tag}`];
        } else {
          return contenttags.en[`${tag}`];
        }
      }
    },
    playContentWrapper(item, e, i) {
      console.log("item-e-i", item, i);
      this.acceptPlayer = false;
      this.activeIndex = i;
      let removeActive = document.getElementsByClassName(
        "feat-card-box-contain"
      );
      for (let i = 0; i < removeActive.length; i++) {
        if (removeActive[i].classList.contains("active-slide")) {
          removeActive[i].classList.remove("active-slide");
        }
      }
      document.getElementById(`card-${i}`).classList.add("active-slide");
      this.selectedItem = item;
      if (item && item.trailer && this.autoPlayInFeatureCaurosel) {
        this.handleAutoPlay(false);
      }
    },
    carouseldata(e) {
      let objectid =
        e.srcElement &&
        e.srcElement.attributes.objectid &&
        e.srcElement.attributes.objectid.value
          ? e.srcElement.attributes.objectid.value
          : "";

      let item = this.contents.filter((data) => {
        if (data.objectid == objectid) return data;
      });
      this.playContent(item[0], null);
    },
    //fetch featured carousel card height.
    fetchCardHeight() {
      let bodyWidth = document.body.offsetWidth - 600;
      let cardHeight = Math.round(bodyWidth / (16 / 9));
      if (cardHeight) {
        this.cardHeight = cardHeight + 80;
        if (this.isTablet()) {
          this.cardHeight += 50;
        }
      }
    },

    //fetch poster for featured content.
    getPoster(content, from) {
      if (Array.isArray(content.poster)) {
        if (content.poster && content.poster.length) {
          let index = content.poster.findIndex((element) => {
            return (
              (element.quality === "HD" || element.quality === "SD") &&
              element.postertype === "LANDSCAPE"
            );
          });
          // console.log("getPoster", index);
          if (index > -1) {
            return screen.width > 768
              ? content.poster[index].filelist[1].filename
              : content.poster[index].filelist[1].filename;
          }
        } else {
          return "https://d31ib6xnmsvhmh.cloudfront.net/POSTER/ll2ufl37xpr_LANDSCAPE_LOW.jpg";
        }
      } else {
        if (content.poster) {
          return this.localDisplayLang === "ara"
            ? content.poster.LANDSCAPE.ar
            : content.poster.LANDSCAPE.en;
        } else if (content.thumbnail) {
          return content.thumbnail;
        }
      }
    },

    //play item from featured carousel.
    playContent(item, e) {
      if (e && (e.target.nodeName == "svg" || e.target.nodeName == "path")) {
        console.log("play content aborted : ", item);
        return;
      }
      if (item.hasOwnProperty("deeplink") && item.deeplink) {
        let id = item.deeplink.split("/")[3];
        if (id == "plan") {
          let planid = item.deeplink.split("/")[4];
          let coupon = "";

          if (item.deeplink.split("/")[5]) {
            coupon = item.deeplink.split("/")[5];
          }

          const routeParams = {
            planId: planid,
          };

          if (coupon) {
            routeParams.coupon = coupon;
          }

          this.$router.push({
            name: "planandcoupondeeplink",
            params: routeParams,
          });
          // }
        } else {
          this.$router.push({ name: "screen", params: { section: `${id}` } });
        }
      } else if (item.hasOwnProperty("objectid")) {
        let currentLanguage = this.getCurrentLanguageUrlBase();

        if (screen.width > 0) {
          if (item.hasOwnProperty("objectid")) {
            if (item.category == "TVSHOW") {
              let title = this.spacialCharEncoding(item.defaulttitle);

              this.$router.push({
                name: "detailPage",
                params: {
                  contentId: item.objectid.toLowerCase(),
                  mediaTitle: title.toLowerCase(),
                  lang: currentLanguage,
                },
                children: [{ name: "episodes", params: { id: 1 } }],
              });
            } else {
              let title = this.spacialCharEncoding(item.defaulttitle);
              this.$router.push({
                name: "detailPageMovie",
                params: {
                  mediaTitle: title.toLowerCase(),
                  contentId: item.objectid.toLowerCase(),
                  lang: currentLanguage,
                },
              });
            }
          }
        } else {
          //this.$router.push({ name: "detail", params: { contentid: item.objectid } });
        }
      }
    },

    //construct carousel for featured contents.
    renderCarousel() {
      if (document.getElementById("featured-carousel")) {
        this.$nextTick(() => {
          if (this.slider) return;
          if (!document.getElementById("featured-carousel")) return;

          let tanyPros = {
            startIndex: 0,
            autoplayDirection: "forward",
          };
          const tnsPayload = {
            container: "#featured-carousel",
            navContainer: "#feat-nav-container",
            controlsContainer: "#feature-slider-controls",
            slideBy: 1,
            // autoplay: true,
            autoplayHoverPause: true,
            autoplayButtonOutput: false,
            autoHeight: true,
            freezable: false,
            loop: false,
            rewind: false,
            // controls: false,
            nav: true,
            speed: 400,
            items: 4,
            startIndex: tanyPros.startIndex,
            autoplayDirection: tanyPros.autoplayDirection,
            gutter: 5,
            responsive: {
              1200: {
                items: 4,
                gutter: 10,
                edgePadding: 320,
              },
              1024: {
                items: 4,
                gutter: 10,
                edgePadding: 150,
              },
            },
          };
          this.slider = tns(tnsPayload);
          // Get slider information
          const sliderInfo = this.slider.getInfo();
          document.getElementById(`card-0`).classList.add("active-slide");
          // Get active slide index
          this.activeIndex = sliderInfo.index;
          console.log(`Active slide index: ${this.activeIndex}`);

          // Get total number of slides
          const totalSlides = sliderInfo.slideCount;
          console.log(`Total number of slides: ${totalSlides}`);
          // ...and so on
          let contentsData = this.contents[0];
          if (
            contentsData &&
            contentsData.trailer &&
            this.autoPlayInFeatureCaurosel
          ) {
            this.handleAutoPlay(false);
          }
        });
      }
    },
    //button navigator for featured carousel.
    navBtnClicked(key) {
      if (this.slider) {
        this.slider.play();
        const activeIndex = this.slider.getInfo().index;
      }
    },

    handleAutoPlay(status = false) {
      // this.showPinModal = false;
      let pgrating = this.contents[this.activeIndex].pgrating;
      if (!this.subscriberid && !status) {
        if (!this.isContentPlayableForAnonymoususer()) {
          // eventBus.$emit("open-age-restriction-modal", true);
          this.showAgePopUp = true;
          return;
        }
      }
      if (
        !this.isContentPlayableParentalLock(
          this.getCurrentProfile(),
          pgrating
        ) &&
        !status
      ) {
        if (this.parentalControlsEnabled) {
          this.callbackParentalLockFlag = "TRAILER-AUTO-PLAY";
          this.showPinModal = true;
          return;
        }
      }
      setTimeout(() => {
        this.allowAutoPlay = true;
        this.acceptPlayer = true;
      }, 1000);
    },

    onAgeModalActionAccept(accept = false) {
      if (accept) {
        this.showAgePopUp = false;
        this.handleAutoPlay(true);
      } else {
        this.showAgePopUp = false;
      }
    },

    closePinModal() {
      this.showPinModal = false;
      this.callbackParentalLockFlag = "";
    },

    onPinModalActionComplete(enteredPin) {
      this.isLoaderEnabled = true;
      this.pinModalLoading = true;

      this.confirmDetailParentPin = enteredPin;
      let payload = {
        pin: this.confirmDetailParentPin,
      };
      this.parentalControlVerifyOldPin(payload)
        .then((data) => {
          this.pinModalLoading = false;
          if (data.data.reason) {
            this.pinModalError = this.$t(data.data.reason);
            return;
          } else if (data.data.success) {
            //this.showPinModal = false;
            this.isLoaderEnabled = false;
            if (this.callbackParentalLockFlag == "TRAILER-AUTO-PLAY") {
              this.handleAutoPlay(true);
            }

            this.closePinModal();
          }
        })
        .catch((error) => {
          console.log("parentalControlVerifyOldPin error -> ", error);
        });
    },

    onForgotPinModalActionComplete(data) {
      // forget pin reset api call
      this.loading = true;
      let forgetPinPayload = {
        pin: data.pin,
        OTP: data.otp,
      };
      this.parentalControlResetPin(forgetPinPayload)
        .then((data) => {
          this.loading = false;
          if (data.data.reason) {
            this.forgotPinModalError = this.$t(data.data.reason);
            return;
          } else if (data.data.success) {
            this.showForgotPinModal = false;
          }
          console.log("Resret Pin Response", data);
        })
        .catch((error) => {
          console.log("parentalControlResetPin error -> ", error);
        });
    },

    openForgotPinModal() {
      this.showForgotPinModal = true;
      this.loading = true;
      //call api to get otp
      let payload = {
        otp_type: "parentalControlPinUpdate",
      };
      //do something here. (Make call to the API)
      //received otp to email
      this.parentalControlPinSet(payload)
        .then((data) => {
          this.loading = false;
          if (data.data.reason) {
            this.pinModalError = this.$t(data.data.reason);
            return;
          } else if (data.data.success) {
            console.log("parentalControlPinSet", data);
          }
        })
        .catch((error) => {
          console.log("parentalControlPinSet error -> ", error);
        });
    },

    isContentPlayableForAnonymoususer() {
      // let ageRatingList = this.appConfig.ageRatingList;
      // let ageLimit = this.appConfig.ageLimit;
      let contentsData = this.contents[this.activeIndex];
      let ContentPgrating = contentsData.pgrating;
      // for (let rating in ageRatingList) {
      //  if (rating == ageLimit) {
      //    return ageRatingList[rating].includes(ContentPgrating);
      //  }
      // }
      if (ContentPgrating == "A" || ContentPgrating.includes("18")) {
        return false;
      }
      return true;
    },
    resendOtp() {
      //do something here. (Make call to the API)
      this.loading = true;
      let payload = {
        otp_type: "parentalControlPinUpdate",
      };
      //do something here. (Make call to the API)
      //received otp to email
      this.parentalControlPinSet(payload)
        .then((data) => {
          this.loading = false;
          if (data.data.reason) {
            this.pinModalError = this.$t(data.data.reason);
            return;
          } else if (data.data.success) {
            console.log("parentalControlPinSet", data);
          }
        })
        .catch((error) => {
          console.log("parentalControlPinSet error -> ", error);
        });
    },
    closeForgotPinModal() {
      this.showForgotPinModal = false;
    },
    playNextContent() {
      // If the active index is less than the length of the content, go to the next item
      if (this.activeIndex < this.contents.length - 1) {
        this.activeIndex += 1;
      } else {
        // Reset the active index to 0 when reaching the end (loop back)
        this.activeIndex = 0;
        this.slider.goTo(0);
      }
      // Update selected item and play the next content
      this.selectedItem = this.contents[this.activeIndex];
      this.acceptPlayer = true;
      // Trigger auto-play for the next content
      if (
        this.selectedItem &&
        this.selectedItem.trailer &&
        this.autoPlayInFeatureCaurosel
      ) {
        this.handleAutoPlay(true);
      }
      // Synchronize the nested carousel highlighting
      this.syncNestedCarousel();
    },
    syncNestedCarousel() {
      // Remove the 'active-slide' class from all carousel items
      let removeActive = document.getElementsByClassName(
        "feat-card-box-contain"
      );
      for (let i = 0; i < removeActive.length; i++) {
        removeActive[i].classList.remove("active-slide");
      }
      // Add the 'active-slide' class to the current active item
      let currentCard = document.getElementById(`card-${this.activeIndex}`);
      if (currentCard) {
        currentCard.classList.add("active-slide");
        this.slider.goTo("next");
      }
    },
    sliderNextClicked() {
      const info = this.slider.getInfo();
      this.slider.goTo(info.index + 1); // Move to the next slide
      this.activeIndex = info.index + 1;
      // If the last item is reached, go back to the first item
      if (this.activeIndex >= this.contents.length) {
        this.activeIndex = 0;
        this.slider.goTo(0); // Reset to the first slide
      }
      // Sync the nested carousel and log position
      this.syncNestedCarousel();
    },
    sliderPrevClicked() {
      const info = this.slider.getInfo();
      this.slider.goTo(info.index - 1); // Move to the previous slide
      this.activeIndex = info.index - 1;
      // Sync the nested carousel and log position
      this.syncNestedCarousel();
      setTimeout(() => {
        this.slider.play();
      }, 100);
    },
    playBackEndedListner(action = false) {
      if (action) {
        this.playNextContent(); // Play the next content
        this.acceptPlayer = false; // Reset player acceptance
      }
    },

    titleShow(item) {
      return item.title.toUpperCase();
    },
    durationFormat(time) {
      if (!time) return;
      let totalTime = time;
      let hours = Math.floor(totalTime / 3600);
      let minutes = Math.floor((totalTime - hours * 3600) / 60);

      let showTime =
        (hours ? hours.toString().padStart(2, "0") + this.$t("hr") : "") +
        " " +
        (minutes
          ? minutes.toString().padStart(2, "0") +
            (minutes === 1 ? this.$t("min") : this.$t("mins"))
          : "");

      if (showTime == " ") {
        return time + this.$t("secs");
      } else {
        return showTime;
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateIsMobile);
    window.addEventListener("resize", this.checkIfMobileDevice);
  },
  components: {
    LikeBtn: () =>
      import(
        /* webpackChunkName: "likebutton" */ "@/components/SvgImages/LikeBtn.vue"
      ),
    WatchListBtn: () =>
      import(
        /* webpackChunkName: "watchlistbtn" */ "@/components/watchlist/watchListBtn.vue"
      ),
    detailIcon: () =>
      import(
        /* webpackChunkName: "detailicon" */ "@/components/SvgImages/detailIcon.vue"
      ),
    ResponsivePlayer: () =>
      import(
        /* webpackChunkName: "responsivePlayer" */ "@/components/MiniPlayer/ResponsivePlayer.vue"
      ),
    AgeRestrictionModal: () =>
      import(
        /* webpackChunkName: "ageRestrictionModal" */ "@/components/Popups/Restriction/AgeRestriction.vue"
      ),
    ForgotPinModal: () => import("@/components/utils/ForgotPinModal.vue"),
    PinModal: () =>
      import(
        /* webpackChunkName: "PinModal" */ "@/components/utils/PinModal.vue"
      ),
  },
  mixins: [Utility],
};
</script>
<style lang="scss">
@import "node_modules/tiny-slider/src/tiny-slider.scss";
@import "@/sass/_variables.scss";
@import "./ThumbnailCarousel.scss";

.main-play-wrapper {
  position: relative;
}
</style>